import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isa:false,
    //显示主页
    isHome: true,

    //正确题数
    rNum:0,

    //答对5题进入抽奖
    isGoDraw: false,

    //没答对5题
    isFail:false,

    //进入转盘
    isTurntable: false,

    //中奖
    isWin: false,

    //没中奖
    isNowin: false,
    mes:'',

    //抽中的奖品
    prize:'',

    //不能再次抽奖
    isNoDraw: false,

    // //我的奖品列表
    // prizeDataList:[],

    //提示音
    audioUrl:'',

    //组队页面
    isTeam:false,

    //组队图标
    isTeamIcon: true,

    //神秘礼物
    isMystery: false,

    yzm:'',
    ljphone:'',

    isYzm: false,

    //奖品列表
    isPrize:false,
    isPrizeList:true,

    audioEle:'',
    //上传
    isUpload: false,
  },
  mutations: {
    //动态修改state的属性
    changeStateProperty(state, params) {
      //params.key: state的属性名
      //params.value: 修改的值
      // console.log('params ==> ', params);
      state[params.key] = params.value;
    },
  },
  actions: {
  },
  modules: {
  }
})
