<template>
  <div id="app">
    <router-view />

    <div class="bg-img" v-if="isHome">
      <img class="auto-img" src="./assets/images/bg.png" alt="" />
    </div>
    <div class="bg-img" v-else>
      <img class="auto-img two-bg" src="./assets/images/bg1.png" alt="" />
    </div>
    <!-- <audio src="./assets/audio/1.mp3" loop autoplay></audio> -->
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
export default {
  name: "App",
  computed: {
    ...mapState(["isHome"]),
  },
  data() {
    return {};
  },
};
</script>

<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
}

.bg-img {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
}
.bg-icon {
  position: absolute;
  width: 20%;
  top: 10px;
  left: 15px;
}
.auto-img {
  width: 100%;
  height: auto;
  display: block;
}

@media screen and (min-width: 600px) {

  .auto-img {
    width: 50%;
    height: auto;
    display: block;
    margin: auto;
  }
  .two-bg {
    width: 100%;
    height: auto;
    display: block;
    margin: auto;
  }
}
</style>
