import Vue from 'vue'
import VueRouter from 'vue-router'

//解决冗余导航，例：倒计时返回中途直接按返回
Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {undefined

  return originalPush.call(this, location).catch(err => err)

}

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue')
  },
  // {
  //   path: '/badge',
  //   name: 'Badge',
  //   component: () => import('../views/Badge.vue')
  // },
  // {
  //   path: '/yzm',
  //   name: 'Yzm',
  //   component: () => import('../views/Yzm.vue')
  // },
]

const router = new VueRouter({
  routes
})

export default router
