import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'


import 'lib-flexible/flexible'
import { Button, CountDown, Dialog, Field, Toast, Uploader,RadioGroup, Radio } from 'vant';

Vue.use(Button)
  .use(Field)
  .use(Toast)
  .use(VueAxios, axios)
  .use(CountDown)
  .use(Dialog)
  .use(Uploader)
  .use(Radio)
  .use(RadioGroup)

Vue.config.productionTip = false


axios.defaults.baseURL = '';

axios.interceptors.request.use(config => {
  Toast.loading({
    message: '加载中...',
    duration: 0
  });

  return config;
}, error => {
  // 对请求错误做些什么
  //关闭加载提示
  Toast.clear();
  return Promise.reject(error);
});

// 添加响应拦截器(响应之前触发)
axios.interceptors.response.use(response => {
  // 对响应数据做点什么
  // console.log('响应之前触发');

  //关闭加载提示
  Toast.clear();

  return response;
}, error => {
  // 对响应错误做点什么
  //关闭加载提示
  Toast.clear();
  return Promise.reject(error);
});

new Vue({
	el: '#app',
	router,
	store,
	render: h => h(App),
}).$mount('#app')